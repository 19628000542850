import { Component } from '@angular/core';
import { ProjectsService } from '../services/project.service';
import { AccountService } from '../services/account.service';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-after-login',
  templateUrl: './after-login.component.html',
  styleUrls: ['./after-login.component.scss']
})
export class AfterLoginComponent {

  constructor(
    private accountService: AccountService,
    private projectService: ProjectsService,
    public auth: AngularFireAuth,
    private router: Router
  ) {}

  ngOnInit() {
    this.auth.authState.subscribe(user => {
    if (user) {
      console.log('AUTHSTATE USER', user)
      this.router.navigate(['home']);
      this.accountService.getUserDetail().subscribe({
        next: (res) => {
          // console.log(res);
          if ((res['companies'] || []).length > 0) {
            if (res['companies'].length === 1) {
              this.router.navigate([res['companies'][0] ,'projects']);
            } else {
              console.log('more than one company');
              this.router.navigate([res['companies'][0] ,'projects']);
            }
            // this.projectService.setCompanyId(res['companies'][0].id);
          }
        }, error: (err) => {
          console.log(err);
        }
      });
    } else {
      console.log('AUTHSTATE USER EMPTY', user)
      // this.currentUser = null;
      this.router.navigate(['auth','login']);
    }
    });
  }
}
