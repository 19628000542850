import { Injectable, NgModule, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  ResolveFn,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
// import { LayoutModule } from './layout/layout.module';
// import { LayoutComponent } from './layout/layout.component';
import { AfterLoginComponent } from './after-login/after-login.component';
import { setLayout } from './layout/layout.resolver';
import { LayoutService, PageLayout } from './layout/layout.service';
import { AuthorizedLayoutComponent } from './layout/authorized-layout/authorized-layout.component';
import { Observable } from 'rxjs';
// import { LoginComponent } from './auth/login/login.component';
// import { GenericComponent as AuthGenericComponent } from './auth/generic/generic.component';
// import { LogoutComponent } from './auth/logout/logout.component';

// export const heroResolver: ResolveFn<Hero> = (  route: ActivatedRouteSnapshot,  state: RouterStateSnapshot,) => {
//   return inject(HeroService).getHero(route.paramMap.get('id')!);
// };

// @Injectable({ providedIn: 'root' })
// export class HeroResolver implements Resolve<number | undefined> {
//   constructor(private service: LayoutService) {}
//   resolve(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ): Observable<number| undefined>|Promise<number| undefined>|number| undefined {
//     return this.service.getHero(route.paramMap.get('id'));
//   }
// }

export const companyIdResolver: ResolveFn<string | number | undefined> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const layoutService = inject(LayoutService);
  let companyId = route.paramMap.get('companyId')!;
  // .getHero(
  // );
  layoutService.setCompanyId(companyId);
  return companyId;
};

const routes: Routes = [
  // { path: 'home', component: LayoutComponent },

  // ---------------------------------------------------

  {
    path: ':companyId/:projectId/tasks',
    // component: AuthorizedLayoutComponent,
    loadChildren: () =>
      import('./tasks/tasks.module').then((m) => m.TasksModule),
    resolve: {
      layout: setLayout(PageLayout.Authorized),
      companyId: companyIdResolver,
    },
  },
  {
    path: ':companyId/projects',
    loadChildren: () =>
      import('./projects/projects.module').then((m) => m.ProjectsModule),
    resolve: {
      layout: setLayout(PageLayout.Authorized),
      companyId: companyIdResolver,
    },
  },
  {
    path: 'companies',
    loadChildren: () =>
      import('./company/company.module').then((m) => m.CompanyModule),
    resolve: {
      layout: setLayout(PageLayout.Authorized),
      companyId: companyIdResolver,
    },
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./company-team/company-team.module').then(
        (m) => m.CompanyTeamModule
      ),
    resolve: {
      layout: setLayout(PageLayout.Authorized),
      companyId: companyIdResolver,
    },
  },
  {
    path: ':companyId/daily-resume',
    loadChildren: () =>
      import('./daily-resume/daily-resume.module').then(
        (m) => m.DailyResumeModule
      ),
    resolve: {
      layout: setLayout(PageLayout.Authorized),
      companyId: companyIdResolver,
    },
  },
  {
    path: ':companyId/users',
    loadChildren: () =>
      import('./company-users/company-users.module').then(
        (m) => m.CompanyUsersModule
      ),
    resolve: {
      layout: setLayout(PageLayout.Authorized),
      companyId: companyIdResolver,
    },
  },
  // ---------------------------------------------------

  {
    path: 'personal-plan',
    loadChildren: () =>
      import('./personal-plan/personal-plan.module').then(
        (m) => m.PersonalPlanModule
      ),
    resolve: {
      layout: setLayout(PageLayout.Authorized),
      companyId: companyIdResolver,
    },
  },

  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    // component: AuthGenericComponent,
    // children: [
    //   {
    //     path: 'login', // child route path
    //     // title: resolvedChildATitle,
    //     component: LoginComponent, // child route component that the router renders
    //   },
    //   {
    //     path: 'logout',
    //     // title: 'child b',
    //     component: LogoutComponent, // another child route component that the router renders
    //   },
    // ],
  },

  // // {
  // //   path: ':companyId/:projectId/tasks',
  // //   loadChildren: () =>
  // //     import('./tasks/tasks.module').then((m) => m.TasksModule),
  // //   // component: AuthGenericComponent,
  // //   // children: [
  // //   //   {
  // //   //     path: 'login', // child route path
  // //   //     // title: resolvedChildATitle,
  // //   //     component: LoginComponent, // child route component that the router renders
  // //   //   },
  // //   //   {
  // //   //     path: 'logout',
  // //   //     // title: 'child b',
  // //   //     component: LogoutComponent, // another child route component that the router renders
  // //   //   },
  // //   // ],
  // // },
  // {
  //   path: ':companyId/projects',
  //   loadChildren: () =>
  //     import('./projects/projects.module').then((m) => m.ProjectsModule),
  //   // component: AuthGenericComponent,
  //   // children: [
  //   //   {
  //   //     path: 'login', // child route path
  //   //     // title: resolvedChildATitle,
  //   //     component: LoginComponent, // child route component that the router renders
  //   //   },
  //   //   {
  //   //     path: 'logout',
  //   //     // title: 'child b',
  //   //     component: LogoutComponent, // another child route component that the router renders
  //   //   },
  //   // ],
  // },
  // {
  //   path: 'companies',
  //   loadChildren: () =>
  //     import('./company/company.module').then(
  //       (m) => m.CompanyModule
  //     ),
  // },
  // {
  //   path: ':companyId/daily-resume',
  //   loadChildren: () =>
  //     import('./daily-resume/daily-resume.module').then(
  //       (m) => m.DailyResumeModule
  //     ),
  // },
  // {
  //   path: ':companyId/users',
  //   loadChildren: () =>
  //     import('./company-users/company-users.module').then(
  //       (m) => m.CompanyUsersModule
  //     ),
  // },
  // {
  //   path: ':companyId/teams',
  //   loadChildren: () =>
  //     import('./company-team/company-team.module').then(
  //       (m) => m.CompanyTeamModule
  //     ),
  //   // component: AuthGenericComponent,
  //   // children: [
  //   //   {
  //   //     path: 'login', // child route path
  //   //     // title: resolvedChildATitle,
  //   //     component: LoginComponent, // child route component that the router renders
  //   //   },
  //   //   {
  //   //     path: 'logout',
  //   //     // title: 'child b',
  //   //     component: LogoutComponent, // another child route component that the router renders
  //   //   },
  //   // ],
  // },
  { path: 'after-login', component: AfterLoginComponent },
  { path: '**', redirectTo: '/after-login' },
  { path: '', redirectTo: '/after-login', pathMatch: 'full' },
  // { path: '', redirectTo: '/dashboard', pathMatch: 'full', outlet: "main"},
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes
      // { enableTracing: true }
    ),
    // LayoutModule
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
